const EC = {};
(($) => {

	EC.cssClasses = {
		"selected": "ec-selected",
	}

	EC.formById = ( formId ) => {
		let $forms = ( formId ) ? document.querySelectorAll(`#gform_${formId}`) : document.querySelectorAll('.gform_wrapper form');
		// WooCommerce Gravity Forms Product Add-Ons compatibility
		if ( !$forms.length && document.querySelectorAll('.gform_variation_wrapper').length ) {
			$forms = document.querySelectorAll('.gform_variation_wrapper');
		}
		return $forms;
	};

	EC.fluidEmbeds = () => {

		document.querySelectorAll(".ec-cmp-html iframe").forEach((embed, i) => {
			if ( !embed.closest('.ec-fluid-wrapper') ) {
				let box = embed.getBoundingClientRect();

				let w = ( embed.hasAttribute('width') && embed.getAttribute('width').trim() !== "" ) ? parseFloat( embed.getAttribute('width') ) : box.width;
				let h = ( embed.hasAttribute('height') && embed.getAttribute('height').trim() !== "" ) ? parseFloat( embed.getAttribute('height') ) : box.height;

				let aspectRatio = h / w;
				let parentEl = embed.parentNode;

				let wrapper = document.createElement('div');
				wrapper.classList.add('ec-fluid-wrapper');
				wrapper.style.setProperty('--aspect-ratio', aspectRatio.toString());
				parentEl.insertBefore(wrapper, embed);
				embed.remove();
				wrapper.appendChild(embed);

				embed.removeAttribute('width');
				embed.removeAttribute('height');
			}
		});

	};

	EC.$fieldChoices = ( $field ) => {
		if ( !$field ) {
			return false;
		}
		return $field.querySelectorAll('.ginput_container .gchoice:not(.gchoice_select_all)');
	};

	EC.SelectedFields = ( formId ) => {
		const $fields = ( formId ) ? document.querySelectorAll(`#gform_${formId} .ec-field`) : document.querySelectorAll('.gform_wrapper form .ec-field');
		$fields.forEach( $field => {
			const $cards = $field.querySelectorAll('.ec-card');
			EC.$fieldChoices($field).forEach(($choice, i) => {
				if ( $choice.querySelector('input:checked') ) {
					$cards[i].classList.add( EC.cssClasses.selected );
				}
			});
		});
		EC.fluidEmbeds();
	};

	EC.updatePriceDisplay = () => {
		document.querySelectorAll('.ec-price').forEach($price => {
			let $pfield = $price.closest('.gfield');
			if ( $pfield.classList.contains('gfield--type-option') || $pfield.classList.contains('gfield--type-product') ) {

				let formId = $pfield.closest('.gform_fields').id;
				formId = formId.substring(formId.indexOf('gform_fields_') + 'gform_fields_'.length);

				let fieldId = $pfield.id;
				fieldId = fieldId.substring(fieldId.indexOf(`field_${formId}_`) + `field_${formId}_`.length);

				let $pcard = $price.closest('.ec-card');
				let $plabel = $pfield.querySelector(`.gchoice label#${$pcard.getAttribute('aria-labelledby')}`);
				let $pinput = document.getElementById( $plabel.getAttribute('for') );
				let price = ( $pinput.value.indexOf('|') !== -1 ) ? $pinput.value.substring( $pinput.value.indexOf('|') + 1 ) : '';

				let $selectedInput = $pfield.querySelector(`.gchoice input:checked`);
				let selectedPrice = ( $selectedInput && $selectedInput.value.indexOf('|') !== -1 ) ? $selectedInput.value.substring( $selectedInput.value.indexOf('|') + 1 ) : '';

				let priceText = $pfield.classList.contains('gfield--type-product') ? selectedPrice : $pinput.closest('.gchoice').querySelector('.ginput_price').innerHTML;

				if ( typeof EC.currency !== 'undefined' ) {
					priceText = EC.currency.toMoney(price, true);

					if ( gform.applyFilters('ec_use_currency_elements', true) === true ) {
						if ( EC.currencyConfig.decimal_separator && EC.currencyConfig.decimals ) {
							let decimalsIndex = priceText.indexOf(EC.currencyConfig.decimal_separator);
							let decimalsText = priceText.substring( decimalsIndex, decimalsIndex + (EC.currencyConfig.decimals + 1) );
							priceText = priceText.replace(decimalsText, `<span class="ec-currency-decimals">${decimalsText}</span>`);
						}
						if (EC.currencyConfig.symbol_left) {
							priceText = priceText.replace(EC.currencyConfig.symbol_left, `<span class="ec-currency-symbol-left">${EC.currencyConfig.symbol_left}</span>`);
						}
						if (EC.currencyConfig.symbol_right) {
							priceText = priceText.replace(EC.currencyConfig.symbol_right, `<span class="ec-currency-symbol-right">${EC.currencyConfig.symbol_right}</span>`);
						}
					}
				}

				priceText = gform.applyFilters('ec_choice_price', priceText, selectedPrice, price, formId, fieldId);
				$price.innerHTML = priceText ? priceText : "&nbsp;";
			}
		});
	};

	EC.onInputChange = (input) => {
		let $thisLabel = document.querySelector(`label[for="${input.id}"]`);
		let $thisCard = input.closest('.gfield').querySelector(`.ec-card[aria-labelledby="${$thisLabel.id}"]`);
		if ( $thisCard ) {
			$thisCard.classList.toggle( EC.cssClasses.selected, input.checked );
		}
		if ( input.type === "radio" && input.checked ) {
			input.closest('.gfield').querySelectorAll(`.ec-card:not([aria-labelledby="${$thisLabel.id}"])`).forEach($otherCard => {
				$otherCard.classList.remove( EC.cssClasses.selected );
			});
		}
	};

	EC.SetUpFields = ( formId, currentPage ) => {

		EC.formById( formId ).forEach( $form => {

			// move ec to before the ginput_container
/*
			$form.querySelectorAll('.ec-cards-wrap').forEach($wrap => {
				let $legend = $wrap.closest('.gfield').querySelector('.gfield_label');
				$wrap.remove();
				$legend.after($wrap);
			});
*/

			// poll/quiz fields can have the order randomised, so we want to match that order
			$form.querySelectorAll('.ec-field.gfield--type-poll, .ec-field.gfield--type-quiz').forEach($field => {
				$field.querySelectorAll('.gchoice input').forEach(($input, i) => {
					let $card = $field.querySelector(`.ec-card[for="${$input.id}"]`);
					$card.style.order = ( i + 1 ).toString();
				});
			});

			$form.querySelectorAll('.ec-field .ginput_container').forEach($container => {
				if ( !$container.classList.contains('ec-sr') ) {
					$container.classList.add('ec-sr');
				}
				$container.querySelectorAll('label').forEach($label => {
					$label.classList.add('ec-sr');//ec-hide
				});
			});

			$form.querySelectorAll('.ec-field .ec-card').forEach(($card, i) => {

				if ( $card.classList.contains('ec-card-has-button') ) {
					/*
					$card.addEventListener('click', (e) => {
						e.preventDefault();
					});
					*/
					$card.querySelectorAll('.ec-cmp-content a').forEach($link => {
						$link.setAttribute('target', '_blank');
						$link.setAttribute('rel', 'noopener noreferrer');
						/*
						$link.addEventListener('click', (e) => {
							window.open(e.currentTarget.href);
						});
						*/
					});
					$card.querySelectorAll('.ec-button').forEach($button => {
						$button.addEventListener('click', (e) => {
							e.preventDefault();
							let $thisCard = e.currentTarget.closest('.ec-card');
							//let $thisInput = $thisCard.closest('.gfield').querySelector(`.gchoice input#${$thisCard.getAttribute('for')}`);
							let $thisLabel = $thisCard.closest('.gfield').querySelector(`.gchoice label#${$thisCard.getAttribute('aria-labelledby')}`);
							let $thisInput = document.getElementById( $thisLabel.getAttribute('for') );
							jQuery($thisInput).click();
							/*
							if ( $thisInput.type === "radio" && !$thisInput.checked ) {
								$thisInput.checked = true;
								$thisInput.dispatchEvent( new Event('change') );
							}
							else if ( $thisInput.type === "checkbox" ) {
								$thisInput.checked = !$thisInput.checked;
								jQuery($thisInput).click();
								$thisInput.dispatchEvent( new Event('change') );
							}
							*/
							e.currentTarget.blur();
						});
					});
				}
				else {
					$card.addEventListener('click', (e) => {
						e.preventDefault();
						let $thisCard = e.currentTarget;
						let $thisLabel = $thisCard.closest('.gfield').querySelector(`.gchoice label#${$thisCard.getAttribute('aria-labelledby')}`);
						let $thisInput = document.getElementById( $thisLabel.getAttribute('for') );
						jQuery($thisInput).click();
					});
				}

				let $label = $card.closest('.gfield').querySelector(`.gchoice label#${$card.getAttribute('aria-labelledby')}`);
				if ( !$label ) {
					console.log('EnhancedChoices :: choice label not found: ', $card.getAttribute('aria-labelledby'));
				}
				let $input = document.getElementById( $label.getAttribute('for') );
				if ( !$input ) {
					console.log('EnhancedChoices :: input not found: ', $card.getAttribute('aria-labelledby'), $label.getAttribute('for'));
					return;
				}

				$input.addEventListener('change', (e) => {
					EC.onInputChange( e.currentTarget );
					clearTimeout(window.__ec_price_change);
					window.__ec_price_change = setTimeout(EC.updatePriceDisplay, 500);
				});
			});
		});

		setTimeout(function(){
			EC.SelectedFields( formId );
			const $productOptionECFields = document.querySelectorAll('.gfield--type-option.ec-field .gchoice input, .gfield--type-product.ec-field .gchoice input');
			if ( $productOptionECFields.length ) {
				$productOptionECFields.forEach(input => {
					if ( input.checked ) {
						EC.onInputChange(input);
					}
				});
				EC.updatePriceDisplay();
			}
			gform.doAction('ec_setup', formId);
		}, 10);

	};

	EC.elementIsVisible = ( el ) => {
		return !!( el.offsetWidth || el.offsetHeight || el.getClientRects().length );
	};

	EC.ConditionalLogic = function( formId ) {
		EC.formById( formId ).forEach( $form => {
			$form.querySelectorAll('.ec-field').forEach( $field => {
				if ( !EC.elementIsVisible($field) ) {
					$field.querySelectorAll('.ec-card').forEach($card => {
						$card.classList.remove( EC.cssClasses.selected );
					});
				}
			});
		});
	};

	$(document).bind('gform_post_render', function(e, formId, currentPage){

		if ( typeof gf_global !== 'undefined' && gf_global.hasOwnProperty('gf_currency_config') && typeof Currency !== 'undefined' ) {
			EC.currencyConfig = structuredClone(gf_global.gf_currency_config);
			EC.currencyConfig.symbol_padding = gform.applyFilters('ec_price_symbol_padding', '');
			EC.currency = new Currency( gform.applyFilters('ec_currency_config', EC.currencyConfig) );
		}

		EC.SetUpFields( formId, currentPage );

	});

	document.addEventListener('gfcf/conversational/navigate/next', (e) => {
		document.querySelectorAll('.gform-conversational .ec-field').forEach($field => {
			$field.closest('.gform-conversational').classList.toggle( 'ec-field-active', $field.classList.contains('active-step') );
		});
	});

	$(document).bind('gform_post_conditional_logic', function(e, formId, fields, isInit){
		EC.ConditionalLogic( formId );
		EC.SelectedFields( formId );
	});


})(jQuery);
